const values = [
  {
    title: 'Byť slobodný/á',
    thumbnail: require('@/assets/values/sloboda.jpg'),
    video: 'https://www.youtube.com/embed/Wi776Cu5SYs',
    text: 'Pokiaľ si chceš určovať podľa seba, kedy budeš pracovať a kedy oddychovať, pozícia obchodníka v Generali bude pre teba ako stvorená. Či už sa rozhodneš spraviť si predĺžený víkend alebo si prispôsobiť stretnutia či iné aktivity svojmu plánu, máš naše slovo, že na to budeš mať vytvorený priestor.',
  },
  {
    title: 'Mať čas na rodinu',
    thumbnail: require('@/assets/values/rodina.jpg'),
    video: 'https://www.youtube.com/embed/UlkkW1XrXpQ',
    text: 'Prácu obchodníka môžeš vďaka našim moderným postupom vykonávať odkiaľkoľvek. Z mobilu, tabletu alebo laptopu. Väčšinu vecí vybavíš aj online a bez zbytočných papierovačiek. Ušetrený čas tak môžeš venovať rodine a byť im nablízku vždy, keď ťa budú potrebovať.',
  },
  {
    title: 'Byť finančne nezávislý/á',
    thumbnail: require('@/assets/values/fin-nezavislost.jpg'),
    video: 'https://www.youtube.com/embed/0wFQrp7HddY',
    text: 'Tvoja energia, ktorú do práce vložíš, bude vždy aj náležite ocenená. V Generali nemáš vrchný strop tvojho zárobku a je len na tebe, kde ťa tvoja šikovnosť dostane. Finančná nezávislosť ti tak dá priestor na plnenie si svojich snov. Chceš precestovať svet či cítiť vietor vo vlasoch? Držíme slovo, že v Generali nič nie je nemožné.',
  },
  {
    title: 'Byť súčasťou tímu',
    thumbnail: require('@/assets/values/team.jpg'),
    video: 'https://www.youtube.com/embed//321-FAZkPiU',
    text: 'V Generali sme všetci na rovnakej vlne. Nie sme len kolegovia, ale tím ľudí s podobným pohľadom na svet. Vždy sa môžeš spoľahnúť na ich pomocnú ruku a podporu, ktorá ťa posunie vpred. U nás sa z kolegov stávajú priatelia, ktorí sa do práce tešia a s ktorými vždy prehodíme viac ako len pár slov.',
  },
];

export default values;
