<template>
  <div
    class="calculation-result"
    :class="{
      '-accent': accent,
    }"
  >
    <div class="left">
      <div class="label">{{ label }}</div>
      <div class="description">{{ description }}</div>
    </div>
    <div class="right">
      <div class="value">{{ valueFormatted }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalculationResult',
  props: {
    label: String,
    description: String,
    value: Number,
    accent: Boolean,
  },
  computed: {
    valueFormatted() {
      const string = this.value.toLocaleString('sk', {
        style: 'currency',
        currency: 'eur',
        useGrouping: true,
        maximumFractionDigits: 0,
      });
      return string;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/globals.scss";

.calculation-result {
  display: flex;
  align-items: center;
  padding: 0.75rem 0;
  color: $dark-gray;
  border-bottom: 1px solid $light-gray;
  .left {
    font-size: 0.875rem;
    flex: 1;
  }
  .label {
    font-weight: 700;
  }
  .right {
    text-align: right;
  }
  .value {
    font-size: 1.75rem;
    @include text-headline;
  }
  &.-accent {
    border-bottom: 2px solid $red-500;
    &,
    .label {
      color: $red-500;
    }
  }
}
</style>
