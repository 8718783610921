<template>
  <div
    class="video-preview"
    :class="{
      '-shadow': shadow,
    }"
    @click="isOpen = true"
    @keydown.escape="isOpen = true"
  >
    <img class="thumbnail" :src="thumbnail" alt="">
    <img class="play" src="@/assets/button-play.svg" alt="">
    <Teleport to="body">
      <div
        v-if="isOpen"
        class="video-lightbox"
      >
        <button class="close" @click="isOpen = false">
          &times;
        </button>
        <iframe
          class="video"
          :src="link + '?rel=0&autoplay=1'"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          autoplay
        />
      </div>
    </Teleport>
  </div>
</template>

<script setup>
import { ref } from 'vue';

defineProps({
  thumbnail: {
    type: String,
    default: 'https://picsum.photos/1280/720',
  },
  link: {
    type: String,
    default: '#',
  },
  shadow: {
    type: Boolean,
  },
});

const isOpen = ref(false);
</script>

<style lang="scss" scoped>
@import "@/styles/globals.scss";

.video-preview {
  display: block;
  position: relative;
  cursor: pointer;
  .thumbnail {
    display: block;
    width: 100%;
    height: auto;
  }
  .play {
    $size: 2.5rem;
    width: $size;
    height: $size;
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0.9;
    margin-top: 0 - ($size / 2);
    margin-left: 0 - ($size / 2);
  }
  &:hover .play {
    opacity: 1;
  }
  &.-shadow {
    box-shadow: $shadow;
  }
}

.video-lightbox {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20;
  color: white;
  background-color: rgba(black, 0.8);

  .close {
    @include reset-button;
    color: white;
    font-size: 2rem;
    line-height: 0.8;
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .video {
    aspect-ratio: 16 / 9;
    width: min(90vw, 160vh);
  }
}
</style>
