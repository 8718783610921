import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import App from './App.vue';

createApp(App)
  .use(createGtm({
    id: 'GTM-P3X7D6R',
    enabled: process.env.NODE_ENV === 'production',
  }))
  .mount('#app');
