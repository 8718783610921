<template>
  <div class="block-calculator">
    <div class="container inner">
      <div class="calculator-header">
        <header class="block-header -width-1 -red-title -md-left">
          <h2>Vypočítaj si, koľko môžeš u nás zarábať</h2>
        </header>
        <BaseButton
          element="a"
          variant="red"
          href="#regiony"
        >
          Pridaj sa
        </BaseButton>
      </div>
      <div class="calculator-main">
        <WidgetCalculator
          @update:reward="reward = $event"
        />
      </div>
      <div class="calculator-result">
        <h2 class="reward">{{ reward }} €</h2>
        <p>
          <strong>Odhadovaná mesačná odmena</strong>
           od 3. mesiaca adaptácie ***
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import BaseButton from '@/components/BaseButton.vue';
import WidgetCalculator from '@/components/WidgetCalculator.vue';

const reward = ref(0);
</script>

<style lang="scss" scoped>
@import "@/styles/globals.scss";

.block-calculator {
  @include block-pad;
  background-color: $lightest-gray;
  .inner {
    @media screen and (min-width: $screen-md) {
      display: flex;
      gap: 1rem;
      align-items: flex-start;
    }
  }
  @media screen and (min-width: $screen-md) {
    background-image: url('@/assets/bg/decor-calc-tr.svg'), url('@/assets/bg/decor-calc-bl.svg'), url('@/assets/bg/decor-calc-br.svg');
    background-size: 1600px;
    background-position: top right, bottom left, bottom right;
    background-repeat: no-repeat;
  }
}

.calculator-header {
  margin-bottom: 2.5rem;
}

.calculator-main,
.calculator-result {
  padding: 2rem;
  background-color: $white;
  margin-bottom: 1rem;
  box-shadow: $shadow;
}

.calculator-result {
  color: $dark-gray;
  .reward {
    @include text-headline;
    font-size: 3rem;
    color: $darkest-gray;
  }
  a {
    text-decoration: none;
    color: $red-500;
  }
}

@media screen and (min-width: $screen-md) {
  .calculator-header {
    margin-right: auto;
  }
  .calculator-main {
    flex: 0 0 464px;
    order: 3;
  }
  .calculator-result {
    flex: 0 0 272px;
    order: 2;
  }
}

</style>
