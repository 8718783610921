<template>
  <div class="widget-calculator white-box">
    <div class="inner">
      <div class="step" v-if="activeStep === 1">
        <header class="step-header">
          <p class="counter">Krok 1/3</p>
          <h3 class="title">Zabezpečenie osôb a vlastného bývania</h3>
          <p class="after">Zadajte počet kusov zmlúv, ktoré predáte mesačne.</p>
        </header>
        <div class="step-body">
          <InputRange
            label="Životné poistenie"
            v-model="salesAmount.zivot"
          />
          <InputRange
            label="Hypotéky"
            labelTooltip="Od 0,5% do 1,2% z čerpanej sumy úveru (v závislosti od typu sprostredkovania)"
            v-model="salesAmount.hypo"
          />
          <InputRange
            label="Pravidelné investovanie"
            v-model="salesAmount.investovanie"
          />
          <InputRange
            label="II. pilier"
            v-model="salesAmount.sporenie2p"
          />
        </div>
        <div class="step-footer">
          <BaseButton block @click="activeStep = 2">
            Pokračovať
          </BaseButton>
        </div>
      </div>
      <div class="step" v-if="activeStep === 2">
        <header class="step-header">
          <p class="counter">Krok 2/3</p>
          <h3 class="title">Zabezpečenie majetku</h3>
          <p class="after">Zadajte počet kusov zmlúv, ktoré predáte mesačne.</p>
        </header>
        <div class="step-body">
          <InputRange
            label="Majetkové poistenie"
            v-model="salesAmount.majetok"
          />
          <InputRange
            label="Povinné zmluvné poistenie"
            v-model="salesAmount.pzp"
          />
          <InputRange
            label="Havarijné poistenie"
            v-model="salesAmount.havarijne"
          />
          <InputRange
            label="Cestovné poistenie"
            v-model="salesAmount.cestovne"
          />
          <InputRange
            label="Podnikateľské poistenie"
            v-model="salesAmount.podnikatelske"
          />
        </div>
        <div class="step-footer">
          <BaseButton block variant="outline" @click="activeStep = 1">
            Späť
          </BaseButton>
          <BaseButton block @click="activeStep = 3">
            Pokračovať
          </BaseButton>
        </div>
      </div>
      <div class="step" v-if="activeStep === 3">
        <header class="step-header">
          <p class="counter">Krok 3/3</p>
          <h3 class="title">Odhadová mesačná odmena</h3>
        </header>
        <div class="step-body">
          <CalculationResult
            label="Odhadová mesačná odmena"
            description="predaj poistných a finančných produktov"
            :value="results.comission"
          />
          <CalculationResult
            label="Odhadovaná mesačná produkcia"
            description="v poistnom biznise"
            :value="results.production"
          />
          <CalculationResult
            label="Fixná zložka *"
            description="v prvý a druhý mesiac adaptácie"
            :value="initialReward1"
          />
          <CalculationResult
            label="Fixná zložka **"
            description="3. - 12. mesiac adaptácie"
            :value="initialReward2"
          />
          <CalculationResult
            label="Odhadovaná mesačná odmena ***"
            description="od 3. mesiaca adaptácie"
            :value="results.comission + initialReward2"
            accent
          />
          <div class="fineprint">
            <p>* Po splnení vzdelávacích a iných aktivít</p>
            <p>** Po splnení vzdelávacích aktivít a výšky produkcie</p>
            <p>*** Podmienkou vyplatenia provízie za produkciu poistných a finančných produktov, štartovacej odmeny a fixnej zložky počas adpatácie nováčika, je absolvovanie povinného vzdelávania zo strany Generali.</p>
          </div>
        </div>
        <div class="step-footer">
          <BaseButton variant="outline" block @click="activeStep = 1">
            Vypočítať znova
          </BaseButton>
          <BaseButton
            element="a"
            variant="red"
            href="#regiony"
            block
          >
            Chcem toľko zarábať
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  ref, reactive, computed, watch, onMounted,
} from 'vue';

import BaseButton from '@/components/BaseButton.vue';
import InputRange from '@/components/InputRange.vue';
import CalculationResult from '@/components/CalculationResult.vue';

const roundToTens = (number) => Math.ceil(number / 10) * 10;

const emit = defineEmits([
  'update:reward',
]);

const salesCommissions = {
  zivot: {
    avg: 581,
    commission: 0.95,
    isInsuranceProduct: true,
  },
  majetok: {
    avg: 130,
    commission: 0.25,
    isInsuranceProduct: true,
  },
  pzp: {
    avg: 150,
    commission: 0.1,
    isInsuranceProduct: true,
  },
  havarijne: {
    avg: 400,
    commission: 0.12,
    isInsuranceProduct: true,
  },
  cestovne: {
    avg: 50,
    commission: 0.15,
    isInsuranceProduct: true,
  },
  podnikatelske: {
    avg: 400,
    commission: 0.18,
    isInsuranceProduct: true,
  },
  hypo: {
    avg: 80000,
    commission: 0.005,
  },
  sporenie2p: {
    avg: 100,
    commission: 0.5,
    isInsuranceProduct: true,
  },
  investovanie: {
    avg: 100,
    commission: 1,
    isInsuranceProduct: true,
  },
};

const activeStep = ref(1);

const salesAmount = reactive({
  zivot: 1,
  majetok: 2,
  pzp: 2,
  havarijne: 1,
  cestovne: 1,
  podnikatelske: 0,
  hypo: 1,
  sporenie2p: 2,
  investovanie: 2,
});

const results = computed(() => {
  const output = {
    comission: 0,
    production: 0,
  };

  const amountsArray = Object.entries(salesAmount);
  amountsArray.forEach(([productName, count]) => {
    const productRefrerence = salesCommissions[productName];
    const amount = count * productRefrerence.avg;
    const commission = amount * productRefrerence.commission;

    output.comission += roundToTens(commission);
    if (productRefrerence.isInsuranceProduct) {
      output.production += amount;
    }
  });

  return output;
});

const initialReward1 = computed(() => {
  const { production } = results.value;
  if (production >= 2000) {
    return 1000;
  }
  if (production >= 1000) {
    return 800;
  }
  return 500;
});

const initialReward2 = computed(() => {
  const { production } = results.value;
  if (production >= 2000) {
    return 1000;
  }
  if (production >= 1500) {
    return 800;
  }
  if (production >= 1000) {
    return 600;
  }
  if (production >= 500) {
    return 400;
  }
  return 0;
});

const rewardFromThirdMonth = computed(() => results.value.comission + initialReward2.value);

const emitReward = () => {
  emit('update:reward', rewardFromThirdMonth.value);
};

watch(rewardFromThirdMonth, () => {
  emitReward();
});

onMounted(() => {
  emitReward();
});

</script>

<style lang="scss" scoped>
@import "@/styles/globals.scss";

.step-header {
  color: $dark-gray;
  margin-bottom: 2rem;
  .counter {
    @include text-overline;
    margin: 0 0 1rem;
  }
  .title {
    @include text-headline;
    font-size: 20px;
    color: $red-500;
    margin: 0 0 0.5rem;
  }
  .after {
    margin: 0 0 0.5rem;
  }
}

.step-body {
  margin-bottom: 1rem;
  color: $dark-gray;
}

.step-footer {
  display: flex;
  gap: 1rem;
}

.fineprint {
  font-size: 0.75rem;
  color: $mid-gray;
}
</style>
