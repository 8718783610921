const stories = [
  {
    poster: require('@/assets/stories/adriana.jpg'),
    name: 'Adriana',
    video: 'https://www.youtube.com/embed/DoUXn2P6mqM',
    type: 'top',
  },
  {
    poster: require('@/assets/stories/lubomir.jpg'),
    name: 'Ľubomír',
    video: 'https://www.youtube.com/embed/1li0QPWqbKY',
    type: 'large',
  },
  {
    poster: require('@/assets/stories/friderika.jpg'),
    name: 'Friderika',
    video: 'https://www.youtube.com/embed/a4MMAxnwjtY',
    type: 'bottom',
  },
  {
    poster: require('@/assets/stories/kristian.jpg'),
    name: 'Kristián',
    video: 'https://youtube.com/embed/9FIMRgGtO0o',
    type: 'top',
  },
  {
    poster: require('@/assets/stories/silvia.jpg'),
    name: 'Silvia',
    video: 'https://youtube.com/embed/eILRf0syWPE',
    type: 'large',
  },
  {
    poster: require('@/assets/stories/samuel.jpg'),
    name: 'Samuel',
    video: 'https://www.youtube.com/embed/H4Qm1njq36w',
    type: 'bottom',
  },
  {
    poster: require('@/assets/stories/alexandra.jpg'),
    name: 'Alexandra',
    video: 'https://www.youtube.com/embed/7_wIz2uqXSU',
    type: 'top',
  },
];

export default stories;
