<!-- eslint-disable vuejs-accessibility/label-has-for -->
<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="input-range">
    <label class="label">{{ label }}</label>
    <input
      type="range"
      class="input"
      :min="min"
      :max="max"
      :value="modelValue"
      @input="$emit('update:modelValue', Number($event.target.value))"
    />
    <div class="knobs">
      <span
        v-for="knob in generateRange(min, max)"
        :key="knob"
        v-text="knob"
      />
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line
const generateRange = function(a,b,c,d) {d=[];c=b-a+1;while(c--)d[c]=b--;return d}

defineProps({
  label: String,
  modelValue: Number,
  min: {
    type: Number,
    default: 0,
  },
  max: {
    type: Number,
    default: 5,
  },
});
</script>

<style lang="scss" scoped>
@import "@/styles/globals.scss";

$track-height: 0.25rem;
$thumb-size: 1rem;

@mixin rangeinput-reset() {
  // Reset
  width: 100%;
  margin: 0;
  appearance: none;
  -webkit-appearance: none;
  background: transparent;
  cursor: pointer;
}

@mixin rangeinput-style-track {
  // Style
  background-color: $light-gray;
  height: $track-height;
}

@mixin rangeinput-style-thumb {
  // Reset
  border: 0;
  appearance: none;
  -webkit-appearance: none;
  box-shadow: none;
  // Style
  width: $thumb-size;
  height: $thumb-size;
  background-color: $red-500;
  border-radius: 50%;
  box-shadow: 0.25rem 0 0 white, -0.25rem 0 0 white;
}

.input-range {
  margin-bottom: 1rem;
  .label {
    display: block;
    color: $mid-gray;
    margin-bottom: 0.25rem;
    @include text-overline;
  }

  .input {
    @include rangeinput-reset();
    &::-webkit-slider-runnable-track {
      @include rangeinput-style-track();
    }
    &::-moz-range-track {
      @include rangeinput-style-track();
    }
    &::-moz-range-thumb {
      @include rangeinput-style-thumb();
    }
    &::-webkit-slider-thumb {
      @include rangeinput-style-thumb();
      margin-top: 0 - (($thumb-size - $track-height) / 2);
    }
    // &::-webkit-range-progress {
    //   background-color: $red-500;
    // }
    &::-moz-range-progress {
      background-color: $red-500;
      height: $track-height;
    }
  }

  .knobs {
    display: flex;
    padding: 0 5px;
    justify-content: space-between;
    color: $mid-gray;
    font-size: 0.75rem;
  }
}

</style>
