<template>
  <a
    v-if="element === 'a'"
    :class="cssClasses"
    :href="href"
  >
    <slot />
  </a>
  <button
    v-else-if="element === 'button'"
    :class="cssClasses"
    :type="type"
  >
    <slot />
  </button>

</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  element: {
    type: String,
    default: 'button',
    validator: (value) => ['button', 'a'].includes(value),
  },
  variant: {
    type: String,
    default: 'red',
    validator: (value) => ['red', 'dark', 'white', 'outline'].includes(value),
  },
  type: {
    type: String,
    default: 'button',
    validator: (value) => ['button', 'submit', 'reset'].includes(value),
  },
  block: {
    type: Boolean,
  },
  href: {
    type: String,
    default: '#',
  },
});

const cssClasses = computed(() => {
  const classes = ['base-button'];
  classes.push(`-variant-${props.variant}`);
  if (props.block) {
    classes.push('-block');
  }
  return classes;
});
</script>

<style lang="scss" scoped>
@import "@/styles/globals.scss";

.base-button {
  @include reset-button;
  @include reset-link;
  display: inline-block;
  font-size: 1rem;
  line-height: 1.25;
  padding: 0.8125rem 2.5rem;
  border: 1px solid transparent;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  min-width: 10rem;
  transition: all .2s;
  &.-variant-red {
    background-color: $red-500;
    color: $white;
    &:hover {
      background-color: darken($red-500, 10);
    }
  }
  &.-variant-dark {
    background-color: $red-dark;
    color: $white;
    &:hover {
      background-color: darken($red-dark, 10);
    }
  }

  &.-variant-white {
    background-color: $white;
    color: $red-500;
    &:hover {
      color: darken($red-500, 10);
    }
  }
  &.-variant-outline {
    background-color: $white;
    color: $red-500;
    border-color: $red-500;
    &:hover {
      background-color: $lightest-gray;
      color: darken($red-500, 10);
    }

  }
  &.-block {
    width: 100%;
  }
  @media screen and (max-width: $screen-sm-max) {
    display: block;
    width: 100%;
  }
}
</style>
